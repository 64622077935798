import strings from "../assets/strings/strings.json";

export function getAllLocalStorage() {
    let values = {},
        keys = Object.keys(localStorage),
        i = keys.length;
    while (i--) {
        values[keys[i]] = localStorage.getItem(keys[i])
    }
    return values;
}


export function setLocalStorage(key, value) {
    localStorage.setItem(key, value);
}

export function getLocalStorage(key) {
    return localStorage.getItem(key);
}


export function getLocateTitlePage(idPage) {
    return strings[`${idPage}_title`]
}

export function clearAllLocalStorage() {
    localStorage.removeItem("auth");
    localStorage.removeItem("branch");
}
