import { BasePage } from "../base-page";
import "./style.css";
/**
 * MUI
 */
import {
    TextField,
    Button,
    Typography,
    Backdrop,
    CircularProgress
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import { CardOption, Header } from "../../components";
//API
import { Product, Login } from "../../model/api";
import { Navigate } from "react-router-dom";
import options from "../../assets/options/options.json";

import make_sale from '../../assets/images/make_sale.jpg';
import sale_list from '../../assets/images/sale_list.jpg';
const ID_PAGE = "dashboard";

class Dashboard extends BasePage {

    constructor() {
        super(ID_PAGE);
        this.state = {
            toggleBackdrop: false,
            navigate: null,
            authSession: this.getLocalStorageSession(),
            localStorage: this.getAllLocalstorage(),
            toggleDialog: false,
            dialogMessage: "",
            options: []
        };
        this.firstApiCall = false;
        this.setToggleBackdrop = this.setToggleBackdrop.bind(this);
        this.setToggleDialog = this.setToggleDialog.bind(this);
        this.optionButtonAction = this.optionButtonAction.bind(this);
        this.closeSessionButtonAction = this.closeSessionButtonAction.bind(this);
        this.getUserOptions = this.getUserOptions.bind(this);
        this.Transition = this.React.forwardRef(function Transition(props, ref) {
            return <Slide direction="up" ref={ref} {...props} />;
        });
    };

    setToggleBackdrop() {
        this.setState({ toggleBackdrop: !this.state.toggleBackdrop });
    };

    setToggleDialog(message) {
        this.setState({ toggleDialog: !this.state.toggleDialog, dialogMessage: message });
    };

    optionButtonAction = async (option) => {
        if (option === "create") {
            this.setState({ navigate: "order-create" });
        } else if (option === "view") {
            this.setState({ navigate: "orders" });
        }
    };

    closeSessionButtonAction = async () => {
        this.setToggleBackdrop();
        const authClass = new Login(null, null, this.state.authSession);
        const authResponse = await authClass.logoutAPI();
        this.setToggleBackdrop();
        if (authResponse !== null) {
            if (authResponse.code === 200) {
                if (authResponse.error === false) {
                    this.cleanAllLocalStorage();
                    this.setState({ navigate: "login" });
                } else {
                    this.setToggleDialog(authResponse.message);
                }
            } else if (authResponse.code === 401) {
                this.cleanAllLocalStorage();
                this.setState({ navigate: "login" });
            }
        } else {
            this.setToggleDialog(this.locates.general_error);
        }
    };


    getUserOptions = async () => {
        this.firstApiCall = true;
        this.setToggleBackdrop();
        const loginClass = new Login(null, null, this.state.authSession);
        const loginResponse = await loginClass.optionsAPI();
        if (loginResponse !== null) {
            if (loginResponse.code === 200) {
                this.setToggleBackdrop();
                if (loginResponse.error === false) {
                    this.setState({ options: loginResponse.data.options });
                }
            } else if (loginResponse.code === 401) {
                this.cleanAllLocalStorage();
                this.setState({ navigate: "login" });
            } else {
                this.setToggleDialog(loginResponse.message);
            }
        } else {
            this.setToggleDialog(this.locates.general_error);
        }
    };

    componentDidMount() {
        super.componentDidMount();
        if (this.firstApiCall === false) {
            this.getUserOptions();
        };
    }


    render() {
        let { navigate } = this.state;
        return (<div className="base-page-container">
            {navigate && (
                <Navigate to={`/${navigate}`} replace={true}/>
            )}
            <Header type="main" title="" closeSession={this.closeSessionButtonAction} backButton={_ => false}/>
            <div className="dashboard-page-container">
                <div className="dashboard-page-card-option-container">
                    {this.state.options.map((data,i) => {
                        return (<CardOption key={i} title={data.title} image={data.image} description={data.description} click={_ => this.optionButtonAction(data.action)}/>)
                    })}
                </div>
            </div>
            <Dialog
                open={this.state.toggleDialog}
                TransitionComponent={this.Transition}
                keepMounted
                onClose={_ => this.setToggleDialog("")}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{"Alerta"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {this.state.dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={_ => this.setToggleDialog("")}>Disagree</Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.state.toggleBackdrop}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>);
    }
}

export default Dashboard;