import { fetchHTTP_POST as apiPost, fetchHTTP_GET as apiGet } from './baseApi';
import apiPath from '../../assets/api-paths/paths.json'
//////////////////////////////////////////////////////////////////////////////////////////////////
export class Login {
    constructor(user, password, auth = null){
        this.user = user;
        this.password = password;
        this.auth = auth;
    };

    async loginAPI() {
        try {
            const path = apiPath.login;
            const headers = null;
            const body = {user:this.user, password:this.password};
            const request = await apiPost(path, body, headers);
            const response = await request.json();
            return response.data;
        } catch (error) {
            return null;
        }
    };
    async logoutAPI() {
        try {
            const path = apiPath.logout;
            const headers = {"auth":this.auth};
            const body = {};
            const request = await apiPost(path, body, headers);
            const httpCode = request.status;
            let response = await request.json();
            response.data.code = httpCode;
            return response.data;
        } catch (error) {
            return null;
        }
    };

    async optionsAPI() {
        try {
            const path = apiPath.getOptions;
            const headers = {"auth":this.auth};
            const request = await apiGet(path, headers);
            const httpCode = request.status;
            let response = await request.json();
            response.data.code = httpCode;
            return response.data;
        } catch (error) {
            return null;
        }
    };
};