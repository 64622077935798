import { fetchHTTP_GET as apiGet, fetchHTTP_POST as apiPost, } from './baseApi';
import apiPath from '../../assets/api-paths/paths.json'
//////////////////////////////////////////////////////////////////////////////////////////////////
export class Delivery {
    constructor(auth, place = null){
        this.auth = auth;
        this.place = place;
    };

    async getDeliveryPlacesAPI() {
        try {
            const path = apiPath.getDeliveryPlaces;
            const headers = {"auth":this.auth};
            const request = await apiGet(path, headers);
            const httpCode = request.status;
            let response = await request.json();
            response.data.code = httpCode;
            return response.data;
        } catch (error) {
            return null;
        }
    };

    async getDeliveryPriceDistanceAPI() {
        try {
            const path = apiPath.getDeliveryPlacePriceDistance;
            const headers = {"auth":this.auth};
            const body = {"place_id":this.place};
            const request = await apiPost(path, body, headers);
            const httpCode = request.status;
            let response = await request.json();
            response.data.code = httpCode;
            return response.data;
        } catch (error) {
            return null;
        }
    };

    async getDeliveryCoordPriceDistanceAPI() {
        try {
            const path = apiPath.getDeliveryCoordPriceDistance;
            const headers = {"auth":this.auth};
            const body = {"coord":this.place};
            const request = await apiPost(path, body, headers);
            const httpCode = request.status;
            let response = await request.json();
            response.data.code = httpCode;
            return response.data;
        } catch (error) {
            return null;
        }
    };
};