import { fetchHTTP_POST as apiPost, fetchHTTP_GET as apiGet  } from './baseApi';
import apiPath from '../../assets/api-paths/paths.json'
//////////////////////////////////////////////////////////////////////////////////////////////////
export class Order {
    constructor(auth, orderData = {}, dateFrom = null, dateTo = null){
        this.orderData = orderData;
        this.auth = auth;
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
    };

    async createOrderAPI() {
        try {
            const path = apiPath.createOrder;
            const headers = { "auth": this.auth };
            const body = this.orderData;
            const request = await apiPost(path, body, headers);
            const httpCode = request.status;
            let response = await request.json();
            response.data.code = httpCode;
            return response.data;
        } catch (error) {
            return null;
        }
    };

    async getOrdersAPI() {
        try {
            const path = apiPath.getOrders;
            const headers = {"auth":this.auth, "dateFrom":this.dateFrom, "dateTo":this.dateTo};
            const request = await apiGet(path, headers);
            const httpCode = request.status;
            let response = await request.json();
            response.data.code = httpCode;
            return response.data;
        } catch (error) {
            return null;
        }
    };

    async getOrderAPI() {
        try {
            const path = apiPath.getOrder;
            const headers = {"auth":this.auth, "order":this.orderData};
            const request = await apiGet(path, headers);
            const httpCode = request.status;
            let response = await request.json();
            response.data.code = httpCode;
            return response.data;
        } catch (error) {
            return null;
        }
    };
};