import { BasePage } from "../base-page";
import "./style.css";
/**
 * MUI
 */
import {
    TextField,
    Button,
    Typography,
    Backdrop,
    CircularProgress
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { CardProduct, FormOrder } from "../../components";
//API
import { Product, Services, Delivery, Payments } from "../../model/api";
import { Navigate } from "react-router-dom";
import { Order } from "../../model/api";
//Component
import { Header } from "../../components";

import options from "../../assets/options/options.json";

const ID_PAGE = "create_order";

class CreateOrder extends BasePage {

    constructor() {
        super(ID_PAGE);
        this.state = {
            toggleBackdrop: false,
            toggleDialog: false,
            dialogMessage: "",
            navigate: null,
            authSession: this.getLocalStorageSession(),
            localStorage: this.getAllLocalstorage(),
            branch: this.getLocalStorageBranchUser(),
            amountVESOrder: 0,
            amountUSDOrder: 0,
            products: [],
            deliveryPriceVES: 0,
            deliveryPriceUSD: 0,
            initialDeliveryPriceVES: 0,
            initialDeliveryPriceUSD: 0,
            readyService:false,
            userCoordinates:null
        };
        this.setToggleBackdrop = this.setToggleBackdrop.bind(this);
        this.handleSetProduct = this.handleSetProduct.bind(this);
        this.formatProductForAddOrRemove = this.formatProductForAddOrRemove.bind(this);
        this.handleConfirmOrderAction = this.handleConfirmOrderAction.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.getServices = this.getServices.bind(this);
        this.getDeliverySectors = this.getDeliverySectors.bind(this);
        this.getPaymentMethods = this.getPaymentMethods.bind(this);
        this.handleDeliveryDataService = this.handleDeliveryDataService.bind(this);
        this.handleDeliveryPlace = this.handleDeliveryPlace.bind(this);
        this.returnService = this.returnService.bind(this);
        this.setToggleDialog = this.setToggleDialog.bind(this);
        this.backButton = this.backButton.bind(this);
        this.products = [];
        this.services = [];
        this.deliveryServiceMultiplierValue = 1;
        this.deliveryPlaces = [];
        this.paymentOptions = [];
        this.modalityOptions = options.createOrder.modalityOptions;
        this.firstApiCall = false

        this.Transition = this.React.forwardRef(function Transition(props, ref) {
            return <Slide direction="up" ref={ref} {...props} />;
        });
    };

    setToggleBackdrop() {
        this.setState({ toggleBackdrop: !this.state.toggleBackdrop });
    };

    setToggleDialog(message) {
        this.setState({ toggleDialog: !this.state.toggleDialog, dialogMessage: message });
    };

    handleSetProduct(item) {
        if (item.command === "add") {
            this.setState({ amountVESOrder: this.state.amountVESOrder + item.price_ves, amountUSDOrder: this.state.amountUSDOrder + item.price_usd, });
        } else {
            this.setState({ amountVESOrder: this.state.amountVESOrder - item.price_ves, amountUSDOrder: this.state.amountUSDOrder - item.price_usd });
        }

        this.formatProductForAddOrRemove(item);
    };

    formatProductForAddOrRemove(prod) {
        let stateProducts = this.state.products;
        let indexFind = null;
        if (this.state.products.length > 0) {
            for (let index = 0; index < this.state.products.length; index++) {
                const product = this.state.products[index];

                if (product.id === prod.id) {
                    indexFind = index;
                }
            }

            if (indexFind !== null) {
                if (prod.command === "remove" && prod.quantity === 0) {
                    delete (stateProducts[indexFind]);
                    stateProducts = stateProducts.filter(el => el !== null)
                    this.setState({ products: stateProducts });
                } else {
                    stateProducts[indexFind] = prod;
                    this.setState({ products: stateProducts });
                }
            } else {
                stateProducts.push(prod);
                this.setState({ products: stateProducts });
            }
        } else {
            stateProducts.push(prod);
            this.setState({ products: stateProducts });
        }
    }

    handleDeliveryDataService = (ev) => {
        if (ev !== null) {
            const { increment_type, increment_value } = ev;
            if (increment_type === "mult") {
                this.deliveryServiceMultiplierValue = increment_value;
                let initDVES = this.state.initialDeliveryPriceVES * this.deliveryServiceMultiplierValue;
                let initDUSD = this.state.initialDeliveryPriceUSD * this.deliveryServiceMultiplierValue;

                let amountVESOrder = (this.state.amountVESOrder - this.state.deliveryPriceVES) + initDVES;
                let amountUSDOrder = (this.state.amountUSDOrder - this.state.deliveryPriceUSD) + initDUSD;

                this.setState({
                    deliveryPriceVES: initDVES,
                    deliveryPriceUSD: initDUSD,
                    amountVESOrder: amountVESOrder,
                    amountUSDOrder: amountUSDOrder
                });

            }
        } else {
            this.setState({
                deliveryPriceVES: 0,
                deliveryPriceUSD: 0,
                amountVESOrder: this.state.amountVESOrder - this.state.deliveryPriceVES,
                amountUSDOrder: this.state.amountUSDOrder - this.state.deliveryPriceUSD
            });
        }
    };

    handleDeliveryPlace = async (ev) => {
        this.setToggleBackdrop();
        this.setState({
            deliveryPriceVES: 0,
            deliveryPriceUSD: 0,
            amountVESOrder: this.state.amountVESOrder - this.state.deliveryPriceVES,
            amountUSDOrder: this.state.amountUSDOrder - this.state.deliveryPriceUSD
        });
        const idPlace = ev;
        const deliveryClass = new Delivery(this.state.authSession, idPlace);
        const deliveryResponse = await deliveryClass.getDeliveryPriceDistanceAPI();
        this.setToggleBackdrop();
        if (deliveryResponse !== null) {
            if (deliveryResponse.error === false) {

                let deliPriceVES = (deliveryResponse.data.price.ves * this.deliveryServiceMultiplierValue).toFixed(2);
                let deliPriceUSD = (deliveryResponse.data.price.usd * this.deliveryServiceMultiplierValue).toFixed(2);
                deliPriceVES = Number.parseFloat(deliPriceVES);
                deliPriceUSD = Number.parseFloat(deliPriceUSD);

                let baseTotalPriceOrderVES = this.state.amountVESOrder - this.state.deliveryPriceVES;
                baseTotalPriceOrderVES = baseTotalPriceOrderVES + deliPriceVES;

                let baseTotalPriceOrderUSD = this.state.amountUSDOrder - this.state.deliveryPriceUSD;
                baseTotalPriceOrderUSD = baseTotalPriceOrderUSD + deliPriceUSD;


                this.setState({
                    initialDeliveryPriceVES: deliveryResponse.data.price.ves,
                    initialDeliveryPriceUSD: deliveryResponse.data.price.usd,
                    deliveryPriceVES: deliPriceVES,
                    deliveryPriceUSD: deliPriceUSD,
                    amountVESOrder: baseTotalPriceOrderVES,
                    amountUSDOrder: baseTotalPriceOrderUSD
                });
            }
        }
    };

    handleConfirmOrderAction = async (ev) => {
        this.setToggleBackdrop();
        const { paymentMethod, paymentReferency, paymentDescription, orderModality, service, locationDetail, userPhone, coordPlace } = ev;
        const products = this.state.products;
        const branch = this.state.branch;
        const payload = {
            products: products,
            modality: orderModality,
            branch: branch,
            amount_ves: this.state.amountVESOrder,
            amount_usd: this.state.amountUSDOrder,
            payment_method: paymentMethod,
            payment_referency: paymentReferency,
            payment_description: paymentDescription,
            service: service,
            location_description: locationDetail,
            customer_phone: userPhone,
            user_coordinates:coordPlace
        };
        const orderClass = new Order(this.state.authSession, payload);
        const orderResponse = await orderClass.createOrderAPI();
        this.setToggleBackdrop();
        if (orderResponse !== null) {
            if (orderResponse.code === 200) {
                if (orderResponse.error === false) {
                    //Se creo la orden
                    this.setState({ navigate: `order/${orderResponse.data.order.id}` });
                } else {
                    this.setToggleDialog(orderResponse.message);
                }
            } else if (orderResponse.code === 401) {
                this.cleanAllLocalStorage();
                this.setState({ navigate: "login" });
            } else {
                this.setToggleDialog(orderResponse.message);
            }
        }
    };

    getProducts = async () => {
        this.firstApiCall = true;
        this.setToggleBackdrop();
        const productClass = new Product(this.state.authSession);
        const productResponse = await productClass.getProductsAPI();
        if (productResponse !== null) {
            if (productResponse.code === 200) {
                if (productResponse.error === false) {
                    this.products = productResponse.data.products;
                    return { error: false, auth: true };
                }
            } else if (productResponse.code === 401) {
                return { error: true, message: this.locates.general_error, auth: false };
            } else {
                return { error: true, message: productResponse.message, auth: true };
            }
        } else {
            return { error: true, message: this.locates.general_error, auth: true };
        }
    };

    getServices = async (val) => {
        if (val.error === false) {
            this.firstApiCall = true;
            const servicesClass = new Services(this.state.authSession);
            const servicesResponse = await servicesClass.getDeliveryServicesAPI();
            if (servicesResponse !== null) {
                if (servicesResponse.error === false) {
                    this.services = servicesResponse.data.services;
                    return { error: false, auth: true };
                } else {
                    return { error: true, message: servicesResponse.message, auth: true };
                }
            } else {
                return { error: true, message: this.locates.general_error, auth: true };
            }
        } else {
            return val;
        }
    };

    getDeliverySectors = async (val) => {
        if (val.error === false) {
            const deliveryPlacesClass = new Delivery(this.state.authSession);
            const deliveryPlacesResponse = await deliveryPlacesClass.getDeliveryPlacesAPI();
            if (deliveryPlacesResponse !== null) {
                if (deliveryPlacesResponse.error === false) {
                    this.deliveryPlaces = deliveryPlacesResponse.data.places;
                    return { error: false, auth: true };
                } else {
                    return { error: true, message: deliveryPlacesResponse.message, auth: true };
                }
            } else {
                return { error: true, message: this.locates.general_error, auth: true };
            }
        } else {
            return val;
        }
    };

    getPaymentMethods = async (val) => {
        if (val.error === false) {
            const paymentsClass = new Payments(this.state.authSession);
            const paymentsResponse = await paymentsClass.getPaymentsAvailablesAPI();
            if (paymentsResponse !== null) {
                if (paymentsResponse.error === false) {
                    this.paymentOptions = paymentsResponse.data.payments_options;
                    return { error: false, auth: true };
                } else {
                    return { error: true, message: paymentsResponse.message, auth: true };
                }
            } else {
                return { error: true, message: this.locates.general_error, auth: true };
            }
        } else {
            return val;
        }
    };

    returnService = async (val) => {
        if (val.error === false) {
            this.firstApiCall = false;
            this.setState({readyService: true});
            this.setToggleBackdrop();
        } else {
            this.setToggleBackdrop();
            this.setToggleDialog(val.message);
            if (val.auth === false) {
                this.cleanAllLocalStorage();
                this.setState({ navigate: "login" });
            }
        }
    };

    getCreateOrderData = async () => {
        return this.getProducts().then(this.getServices).then(this.getDeliverySectors).then(this.getPaymentMethods).then(this.returnService).catch(_ => this.setToggleBackdrop())
    }

    backButton() {
        this.setState({ navigate: "dashboard" });
    }

    componentDidMount() {
        super.componentDidMount();
        if (this.firstApiCall === false) {
            this.getCreateOrderData();
        }
    }


    render() {
        let { navigate } = this.state;
        return (<div className="base-page-container">
            {navigate && (
                <Navigate to={`/${navigate}`} replace={true} />
            )}
            <Header type="" title={`Creación de orden: ${Number.parseFloat(this.state.amountVESOrder).toFixed(2)} ${this.locates.ves_currency_literal} ${Number.parseFloat(this.state.amountUSDOrder).toFixed(2)} ${this.locates.usd_currency_literal}`} closeSession={_ => false} backButton={this.backButton}/>
            <div className="create-order-page-container">
                {this.state.readyService === true ? (<>
                <div className="create-order-page-amount-container">
                </div>
                <div className="create-order-page-card-product-container">
                    {this.products.length > 0 ? this.products.map((data, i) => {
                        return (<CardProduct key={i} name={data.name} image={data.img} description={data.description} price_ves={data.price_ves} price_usd={data.price_usd} id={data.id} handleSetProduct={this.handleSetProduct} />)
                    }) : (<></>)}
                </div>
                <div className="create-order-page-form-option-container">
                    <FormOrder deliveryPlaces={this.deliveryPlaces} deliveryServices={this.services} modalityOptions={this.modalityOptions} paymentOptions={this.paymentOptions} createOrderButtonAction={this.handleConfirmOrderAction} isProducts={this.state.amountUSDOrder > 0} deliveryService={this.handleDeliveryDataService} placeSelect={this.handleDeliveryPlace} />
                </div>
                </>) : (<></>)}
            </div>
            <Dialog
                open={this.state.toggleDialog}
                TransitionComponent={this.Transition}
                keepMounted
                onClose={_ => this.setToggleDialog("")}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{"Alerta"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {this.state.dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={_ => this.setToggleDialog("")}>Disagree</Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.state.toggleBackdrop}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>);
    }
}

export default CreateOrder;