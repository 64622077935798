import React from "react";
import "./style.css"
import logo_icon from '../../assets/images/logo_icon.png';
import logo_round from '../../assets/images/logo_round.png';
import strings from "../../assets/strings/strings.json";
import { setLocalStorage, getAllLocalStorage, getLocalStorage, getLocateTitlePage, clearAllLocalStorage } from '../../tools';
import { Navigate } from "react-router-dom";

export class BasePage extends React.Component {
    constructor(idPage) {
        super();
        this.idPage = idPage;
        this.title = getLocateTitlePage(idPage);
        this.buttonStyleBrandPrimary = { backgroundColor: "#033375" };
        this.buttonStyleBrandySecondary = { backgroundColor: "#67C3EF" };
        this.imgLogoIcon = logo_icon;
        this.imgLogoRoundIcon = logo_round;
        this.locates = strings;
        this.localStorage = [];
        this.setLocalStorageSession = this.setLocalStorageSession.bind(this);
        this.getLocalStorageSession = this.getLocalStorageSession.bind(this);
        this.getAllLocalstorage = this.getAllLocalstorage.bind(this);
        this.setLocalStorageUserBranch = this.setLocalStorageUserBranch.bind(this);
        this.getLocalStorageBranchUser = this.getLocalStorageBranchUser.bind(this);
        this.cleanAllLocalStorage = this.cleanAllLocalStorage.bind(this);
        this.getMonth = this.getMonth.bind(this);
        this.getDateMonthYear = this.getDateMonthYear.bind(this);
        this.getUrl = this.getUrl.bind(this);
        this.state = {navigate:null};
        this.React = React;
    };

    componentDidMount() {
        document.title = this.title;
        this.localStorage = getAllLocalStorage();

        if(this.idPage !== "login" && this.localStorage.hasOwnProperty("auth") === false && this.localStorage.hasOwnProperty("branch") === false) {
            this.setState({ navigate: "login" });
        } else if(this.idPage === "login" && this.localStorage.hasOwnProperty("auth") === true && this.localStorage.hasOwnProperty("branch") === true) {
            this.setState({ navigate: "dashboard" });
        }
    }

    setLocalStorageSession(session) {
        setLocalStorage("auth", session);
    }

    setLocalStorageUserBranch(branch) {
        setLocalStorage("branch", branch);
    }

    getLocalStorageSession() {
        return getLocalStorage("auth");
    }

    getLocalStorageBranchUser() {
        return getLocalStorage("branch");
    }

    getAllLocalstorage(){
        return getAllLocalStorage();
    }

    cleanAllLocalStorage(){
        clearAllLocalStorage();
    }

    getMonth(){
        const date = new Date();
        return date.getMonth() + 1;
    }

    getDateMonthYear(){
        const date = new Date(), y = date.getFullYear(), m = date.getMonth();
        const firstDay = new Date(y, m, 1);
        const lastDay = new Date(y, m + 1, 0);
        let firstDayString = firstDay.toLocaleDateString(['pt-PT', 'es-VE'], { timeZone: 'America/Caracas' });
        let lastDayString = lastDay.toLocaleDateString(['pt-PT', 'es-VE'], { timeZone: 'America/Caracas' });
        firstDayString = firstDayString.slice(0).split('/')[2] + "-" + firstDayString.slice(0).split('/')[1] + "-" + firstDayString.slice(0).split('/')[0]
        lastDayString = lastDayString.slice(0).split('/')[2] + "-" + lastDayString.slice(0).split('/')[1] + "-" + lastDayString.slice(0).split('/')[0]
        return {first:firstDayString, last:lastDayString};
    }

    getUrl() {
        const currentUrl = window.location;
        return currentUrl.pathname.replaceAll(`/${this.idPage}/`,"");
    }

    render() {
        let { navigate } = this.state;
        return (<>
            {navigate && (
                <Navigate to={`/${navigate}`} replace={true} />
            )} </>);
    }
}