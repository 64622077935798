import { BasePage } from "../base-page";
import "./style.css";
/**
 * MUI
 */
import {
    TextField,
    Button,
    Typography,
    Backdrop,
    CircularProgress
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
//API
import { Order as OrderApi } from "../../model/api";
import { Navigate, useLocation } from "react-router-dom";
//Mapbox
import mapboxgl from 'mapbox-gl';
//Component
import { Header } from "../../components";
const ID_PAGE = "order";

class Order extends BasePage {

    constructor() {
        super(ID_PAGE);
        this.state = {
            toggleBackdrop: false,
            toggleDrawer: false,
            toggleDialog: false,
            dialogMessage: "",
            navigate: null,
            authSession: this.getLocalStorageSession(),
            localStorage: this.getAllLocalstorage(),
            branch: this.getLocalStorageBranchUser(),
            orderId: this.getUrl(),
            order: null
        };
        this.mapboxRef = this.React.createRef();
        mapboxgl.accessToken = "pk.eyJ1IjoicmFwaWRhZ3VhIiwiYSI6ImNscnAzMWx1cDAwaDMybnE3NTY0MnZ6bHgifQ.o6SFYDyVRYIt3or6uWxLRQ";
        this.setToggleBackdrop = this.setToggleBackdrop.bind(this);
        this.toggleDrawer = this.setToggleDrawer.bind(this);
        this.setToggleDialog = this.setToggleDialog.bind(this);
        this.getOrder = this.getOrder.bind(this);
        this.backButton = this.backButton.bind(this);

        this.firstApiCall = false
        this.style = {
            py: 0,
            width: '100%',
            borderRadius: 2,
            border: '1px solid',
            borderColor: 'divider',
            backgroundColor: 'background.paper',
        };
        this.Transition = this.React.forwardRef(function Transition(props, ref) {
            return <Slide direction="up" ref={ref} {...props} />;
        });
    };

    setToggleBackdrop() {
        this.setState({ toggleBackdrop: !this.state.toggleBackdrop });
    };

    setToggleDrawer() {
        this.setState({ toggleDrawer: !this.state.toggleDrawer });
    };

    setToggleDialog(message) {
        this.setState({ toggleDialog: !this.state.toggleDialog, dialogMessage: message });
    };

    getOrder = async () => {
        this.firstApiCall = true;
        this.setToggleBackdrop();
        const orderClass = new OrderApi(this.state.authSession, this.state.orderId);
        const orderResponse = await orderClass.getOrderAPI();
        this.setToggleBackdrop();
        if (orderResponse !== null) {
            if (orderResponse.code === 200) {
                if (orderResponse.error === false) {
                    this.setState({ order: orderResponse.data.order });
                    if(orderResponse.data.order.modality === "Domicilio"){
                        const map = new mapboxgl.Map({
                            container: this.mapboxRef.current,
                            // See style options here: https://docs.mapbox.com/api/maps/#styles
                            style: 'mapbox://styles/mapbox/streets-v11',
                            center: [orderResponse.data.order.customer_coordinates[1], orderResponse.data.order.customer_coordinates[0]],
                            zoom: 15,
                        });
                        const marker = new mapboxgl.Marker({
                            color: "#033375",
                            draggable: false
                        }).setLngLat([orderResponse.data.order.customer_coordinates[1], orderResponse.data.order.customer_coordinates[0]])
                            .addTo(map);
                        // add navigation control (the +/- zoom buttons)
                        map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
                        // clean up on unmount
                        return () => map.remove();
                    }
                }
            } else if (orderResponse.code === 401) {
                this.cleanAllLocalStorage();
                this.setState({ navigate: "login" });
            } else {
                this.setToggleDialog(orderResponse.message);
            }
        } else {
            this.setToggleDialog(this.locates.general_error);
        }
    };

    backButton() {
        this.setState({ navigate: "orders" });
    }

    componentDidMount() {
        super.componentDidMount();
        if (this.firstApiCall === false) {
            this.getOrder();
        };
    }


    render() {
        let { navigate } = this.state;
        return (<div className="base-page-container">
            {navigate && (
                <Navigate to={`/${navigate}`} replace={true} />
            )}
            <Header type="" title="Detalle de orden" closeSession={_ => false} backButton={this.backButton} />
            <div className="order-page-container">
                {this.state.order !== null ? (<>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 250 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">{this.locates.order_list_number}</TableCell>
                                <TableCell align="right">{this.state.order.order_number}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">{this.locates.order_list_date}</TableCell>
                                <TableCell align="right">{this.state.order.date}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">{this.locates.order_list_hour}</TableCell>
                                <TableCell align="right">{this.state.order.hour}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">{this.locates.order_list_products}</TableCell>
                                <TableCell align="right">{this.state.order.products_string}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">{this.locates.order_list_status}</TableCell>
                                <TableCell align="right">{this.state.order.status}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">{this.locates.order_list_user_admin}</TableCell>
                                <TableCell align="right">{this.state.order.user}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">{this.locates.order_list_modality}</TableCell>
                                <TableCell align="right">{this.state.order.modality}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">{this.locates.order_list_branche}</TableCell>
                                <TableCell align="right">{this.state.order.branch}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">{this.locates.ves_currency_literal}</TableCell>
                                <TableCell align="right">{this.state.order.amount_ves}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">{this.locates.usd_currency_symbol}</TableCell>
                                <TableCell align="right">{this.state.order.amount_usd}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">{this.locates.order_list_payment_method}</TableCell>
                                <TableCell align="right">{this.state.order.payment_method}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">{this.locates.order_list_payment_description}</TableCell>
                                <TableCell align="right">{this.state.order.payment_description}</TableCell>
                            </TableRow>
                            {this.state.order.modality === 1 ? <>
                                <TableRow>
                                    <TableCell component="th" scope="row">{this.locates.order_list_delivery_service}</TableCell>
                                    <TableCell align="right">{this.state.order.delivery_service}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">{this.locates.order_list_customer}</TableCell>
                                    <TableCell align="right">{this.state.order.customer_name}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">{this.locates.order_list_phone}</TableCell>
                                    <TableCell align="right">{this.state.order.customer_phone}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">{this.locates.order_list_location}</TableCell>
                                    <TableCell align="right">{JSON.stringify(this.state.order.customer_coordinates)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">{this.locates.order_list_location_description}</TableCell>
                                    <TableCell align="right">{this.state.order.location_description}</TableCell>
                                </TableRow>
                            </> : <></>}
                        </TableBody>
                    </Table>
                </TableContainer>
                </>) : (<></>) }
            </div>
                <div>
                    <div className="map-container" ref={this.mapboxRef} />
                </div>
            <Dialog
                open={this.state.toggleDialog}
                TransitionComponent={this.Transition}
                keepMounted
                onClose={_ => this.setToggleDialog("")}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{"Alerta"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {this.state.dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={_ => this.setToggleDialog("")}>Disagree</Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.state.toggleBackdrop}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>);
    }
}

export default Order;