import { fetchHTTP_GET as apiGet } from './baseApi';
import apiPath from '../../assets/api-paths/paths.json'
//////////////////////////////////////////////////////////////////////////////////////////////////
export class Product {
    constructor(auth, products = {}){
        this.products = products;
        this.auth = auth;
    };

    async getProductsAPI() {
        try {
            const path = apiPath.getProducts;
            const headers = {"auth":this.auth};
            const request = await apiGet(path, headers);
            const httpCode = request.status;
            let response = await request.json();
            response.data.code = httpCode;
            return response.data;
        } catch (error) {
            return null;
        }
    };
};