//0 dev 1 prod 2 localhost
const rapidaguaUrl = ['https://rapidagua-admin-api-dev.rapidaguave.workers.dev','https://rapidagua-admin-api.rapidaguave.workers.dev','http://localhost:8787'];
const ENV = 0;

export async function fetchHTTP_GET(path, headers) {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), 3500);
    const init = {
        method: "GET",
        headers: headers,
        signal: controller.signal
    };
    const response = await fetch(`${rapidaguaUrl[ENV]}${path}`, init);
    clearTimeout(id);
    return response;
};

export async function fetchHTTP_POST(path, body, headers) {
    const bodyData = JSON.stringify(body);
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), 3500);
    const init = {
        method: "POST",
        headers: {
            ...headers,
            "Content-Type": "application/json",
        },
        signal: controller.signal,
        body: bodyData
    };
    const response = await fetch(`${rapidaguaUrl[ENV]}${path}`, init);
    clearTimeout(id);
    return response;
};