import React from "react";
import "./style.css"
import strings from "../../assets/strings/strings.json";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export class CardProduct extends React.Component {
    constructor() {
        super();
        this.name = "";
        this.image = "";
        this.description = "";
        this.id = "";
        this.state = {
            quantity: 0,
            command: ""
        };
        this.addQuantity = this.addQuantity.bind(this);
        this.removeQuantity = this.removeQuantity.bind(this);
        this.priceVES = 0;
        this.priceUSD = 0;
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.quantity !== this.state.quantity){
            this.props.handleSetProduct({
                id: this.id,
                name: this.name,
                quantity:this.state.quantity,
                price_ves: Number.parseFloat(this.priceVES),
                price_usd: Number.parseFloat(this.priceUSD),
                command: this.state.command
            })
        }
    }



    addQuantity(e){
        e.preventDefault();
        this.setState({quantity: this.state.quantity + 1, command: "add"});
    }

    removeQuantity(e){
        e.preventDefault();
        if(this.state.quantity > 0){
            this.setState({quantity: this.state.quantity - 1, command: "remove"});
        }
    }


    render() {

        const { name, image, description, price_ves, price_usd, id } = this.props;
        this.name = name;
        this.image = image;
        this.description = description;
        this.priceVES = price_ves;
        this.priceUSD = price_usd;
        this.id = id;

        return (<div className="card-product-container">
            <div className="card-product">
                <div className="card-product-data">
                    <img src={this.image || ""} alt="product-image" className="card-product-image" height="100"/>
                    <h3>{this.name}</h3>
                    <p>{this.description}</p>
                    <div className="card-product-data-price">
                        <p className="p-no-margin">{this.priceVES} {strings.ves_currency_literal}&nbsp;/&nbsp;</p>
                        <p className="p-no-margin">{this.priceUSD} {strings.usd_currency_literal}</p>
                    </div>
                </div>
                <div className="card-product-actions">
                    <div className="card-product-quantity-button">
                        <IconButton aria-label="delete" size="large" onClick={this.removeQuantity}>
                            <RemoveIcon fontSize="inherit" />
                        </IconButton>
                    </div>
                    <div className="card-product-quantity">
                        {this.state.quantity}
                    </div>
                    <div className="card-product-quantity-button">
                        <IconButton aria-label="delete" size="large" onClick={this.addQuantity}>
                            <AddIcon fontSize="inherit" />
                        </IconButton>
                    </div>
                </div>
            </div>
        </div>);
    }
}