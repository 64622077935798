import { BasePage } from "../base-page";
import "./style.css";
/**
 * MUI
 */
import {
    TextField,
    Button,
    Typography,
    Backdrop,
    CircularProgress
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
//API
import { Navigate } from "react-router-dom";
import { Order } from "../../model/api";
//Assets
import months from '../../assets/months/months.json';
//Component
import { Header } from "../../components";
const ID_PAGE = "orders";

class Orders extends BasePage {

    constructor() {
        super(ID_PAGE);
        this.state = {
            toggleBackdrop: false,
            toggleDrawer: false,
            toggleDialog: false,
            dialogMessage: "",
            navigate: null,
            authSession: this.getLocalStorageSession(),
            localStorage: this.getAllLocalstorage(),
            branch: this.getLocalStorageBranchUser(),
            orders: [],
            order: {},
            monthDate: this.getMonth(),
            monthYear:this.getDateMonthYear()
        };
        this.setToggleBackdrop = this.setToggleBackdrop.bind(this);
        this.toggleDrawer = this.setToggleDrawer.bind(this);
        this.getOrders = this.getOrders.bind(this);
        this.navigateToOrderView = this.navigateToOrderView.bind(this);
        this.getOrdersFromDate = this.getOrdersFromDate.bind(this);
        this.months = months.months;

        this.firstApiCall = false
        this.style = {
            py: 0,
            width: '100%',
            borderRadius: 2,
            border: '1px solid',
            borderColor: 'divider',
            backgroundColor: 'background.paper',
        };
        this.backButton = this.backButton.bind(this);
        this.handlemonthDateChange = this.handlemonthDateChange.bind(this);
    };

    setToggleBackdrop() {
        this.setState({ toggleBackdrop: !this.state.toggleBackdrop });
    };

    setToggleDialog(message) {
        this.setState({ toggleDialog: !this.state.toggleDialog, dialogMessage: message });
    };

    setToggleDrawer(data) {
        this.setState({ toggleDrawer: !this.state.toggleDrawer });
        this.setState({ order: data });
    };

    navigateToOrderView(order) {
        this.setState({ navigate: `order/${order}` });
    };

    handlemonthDateChange = async(ev) => {
        this.setState({ monthDate: ev.target.value });
        await this.getOrdersFromDate(ev.target.value);
    };

    getOrdersFromDate = async (month) => {

        const date = new Date(), y = date.getFullYear();
        const firstDay = new Date(y, month, 1);
        const lastDay = new Date(y, month, 0);

        let firstDayString = firstDay.toLocaleDateString(['pt-PT', 'es-VE'], { timeZone: 'America/Caracas' });
        let lastDayString = lastDay.toLocaleDateString(['pt-PT', 'es-VE'], { timeZone: 'America/Caracas' });

        let first = `${date.getFullYear()}-${month}-${firstDayString.slice(0).split('/')[0]}`;
        let last = `${date.getFullYear()}-${month}-${lastDayString.slice(0).split('/')[0]}`;


        this.setToggleBackdrop();
        const ordersClass = new Order(this.state.authSession, {}, first, last);
        const ordersResponse = await ordersClass.getOrdersAPI();
        this.setToggleBackdrop();
        if (ordersResponse !== null) {
            if (ordersResponse.code === 200) {
                if (ordersResponse.error === false) {
                    this.setState({ orders: ordersResponse.data.orders });
                }
            } else if (ordersResponse.code === 401) {
                this.cleanAllLocalStorage();
                this.setState({ navigate: "login" });
            } else {
                this.setToggleDialog(ordersResponse.message);
            }
        } else {
            this.setToggleDialog(this.locates.general_error);
        }
    };

    getOrders = async () => {
        this.firstApiCall = true;
        this.setToggleBackdrop();
        const ordersClass = new Order(this.state.authSession, {}, this.state.monthYear.first, this.state.monthYear.last);
        const ordersResponse = await ordersClass.getOrdersAPI();
        this.setToggleBackdrop();
        if (ordersResponse !== null) {
            if (ordersResponse.code === 200) {
                if (ordersResponse.error === false) {
                    this.setState({ orders: ordersResponse.data.orders });
                }
            } else if (ordersResponse.code === 401) {
                this.cleanAllLocalStorage();
                this.setState({ navigate: "login" });
            } else {
                this.setToggleDialog(ordersResponse.message);
            }
        } else {
            this.setToggleDialog(this.locates.general_error);
        }
    };

    backButton() {
        this.setState({ navigate: "dashboard" });
    }

    componentDidMount() {
        super.componentDidMount();
        if (this.firstApiCall === false) {
            this.getOrders();
        }
    }


    render() {
        let { navigate } = this.state;
        return (<div className="base-page-container">
            {navigate && (
                <Navigate to={`/${navigate}`} replace={true} />
            )}
            <Header type="" title="Lista de ordenes" closeSession={_ => false} backButton={this.backButton} />
            <div className="view-orders-page-container">
            <FormControl fullWidth sx={{marginTop:0.7}}>
                    <InputLabel id="demo-simple-select-label">{this.locates.order_list_month_date}</InputLabel>
                    <Select
                        defaultValue=""
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.monthDate}
                        label={this.locates.order_list_month_date}
                        onChange={this.handlemonthDateChange}
                    >
                        {this.months.map((data, i) => {
                            return (<MenuItem key={i} value={data.value}>{data.name}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 250 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Fecha</TableCell>
                                <TableCell align="left">Hora</TableCell>
                                <TableCell align="left">Orden</TableCell>
                                <TableCell align="left">Estatus</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.orders.length > 0 ? this.state.orders.map((data, i) => {
                                return (
                                    <TableRow key={i} onClick={_ => this.navigateToOrderView(data.id)} className="view-orders-page-list-order">
                                        <TableCell component="th" scope="row">{data.date}</TableCell>
                                        <TableCell component="th" scope="row">{data.hour}</TableCell>
                                        <TableCell component="th" scope="row">{data.order_number}</TableCell>
                                        <TableCell component="th" scope="row">{data.status}</TableCell>
                                    </TableRow>)
                            }) : (<></>)}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Drawer
                    anchor={'bottom'}
                    open={this.state.toggleDrawer}
                >
                    <div onClick={_ => this.setToggleDrawer({})}>
                        cerrar
                    </div>
                    {JSON.stringify(this.state.order)}
                </Drawer>
            </div>
            <Dialog
                open={this.state.toggleDialog}
                TransitionComponent={this.Transition}
                keepMounted
                onClose={_ => this.setToggleDialog("")}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{"Alerta"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {this.state.dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={_ => this.setToggleDialog("")}>Disagree</Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.state.toggleBackdrop}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>);
    }
}

export default Orders;