import { createSlice } from '@reduxjs/toolkit'
export const auth = createSlice({
    name: 'auth',
    initialState: {
      value: null,
    },
    reducers: {
      set: (state, action) => {
        state.value = action.payload;
      },
      clear: (state) => {
        state.value = null;
      }
    },
  })
  
  export const { set, clear } = auth.actions
  
  export default auth.reducer;