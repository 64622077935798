import { fetchHTTP_GET as apiGet } from './baseApi';
import apiPath from '../../assets/api-paths/paths.json'
//////////////////////////////////////////////////////////////////////////////////////////////////
export class Payments {
    constructor(auth){
        this.auth = auth;
    };

    async getPaymentsAvailablesAPI() {
        try {
            const path = apiPath.getPaymentsAvailable;
            const headers = {"auth":this.auth};
            const request = await apiGet(path, headers);
            const httpCode = request.status;
            let response = await request.json();
            response.data.code = httpCode;
            return response.data;
        } catch (error) {
            return null;
        }
    };
};