import React from "react";
import "./style.css"
import strings from "../../assets/strings/strings.json";

export class CardOption extends React.Component {
    constructor() {
        super();
        this.title = "";
        this.image = "";
        this.description = "";
    }

    componentDidMount() {
    }


    render() {

        const { title, image, description } = this.props;
        this.title = title;
        this.image = image;
        this.description = description;

        return (<div className="card-option-container">
            <div className="card-option" onClick={e => this.props.click()}>
                <img src={this.image} alt="product" className="card-option-image" height="100"/>
                <h2>{this.title}</h2>
            </div>
        </div>);
    }
}