import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Dashboard from './pages/dashboard';
import CreateOrder from './pages/create-order';
import Orders from './pages/orders';
import Order from './pages/order';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './redux/store';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <p>Error 404</p>,
  },
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "order-create",
    element: <CreateOrder />,
  },
  {
    path: "orders",
    element: <Orders />,
  },
  {
    path: "order/:id",
    element: <Order />,
  },
  {
    path: "*",
    element: <App />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
