import React from "react";
import "./style.css"
import strings from "../../assets/strings/strings.json";
import {
    TextField,
    Button,
    Typography
} from '@mui/material';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';

export class FormOrder extends React.Component {
    constructor() {
        super();
        this.locates = strings;
        this.state = {
            paymentMethod: "",
            paymentReferency: "",
            paymentDescription: "",
            orderModality: "",
            service: "",
            place: "",
            branchSale: true,
            locationDetail: "",
            userPhone:"",
            coordPlace:""
        };
        this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
        this.setInputReferencyValue = this.setInputReferencyValue.bind(this);
        this.setInputDescriptionValue = this.setInputDescriptionValue.bind(this);
        this.createOrderButtonAction = this.createOrderButtonAction.bind(this);
        this.handleOrderModalityChange = this.handleOrderModalityChange.bind(this);
        this.handleOrderServiceChange = this.handleOrderServiceChange.bind(this);
        this.handleOrderPlaceChange = this.handleOrderPlaceChange.bind(this);
        this.setInputLocationDetailValue = this.setInputLocationDetailValue.bind(this);
        this.setInputUserPhoneValue = this.setInputUserPhoneValue.bind(this);
    }

    componentDidMount() { }

    handlePaymentMethodChange(ev) {
        this.setState({ paymentMethod: ev.target.value });
    };

    handleOrderModalityChange(ev) {
        this.setState({ orderModality: ev.target.value, branchSale: ev.target.value === 0 || ev.target.value === 2 });
        this.props.deliveryService(ev.target.value !== 1 ? null : {});
    };

    handleOrderServiceChange(ev) {
        let serviceData = null;
        for (let index = 0; index < this.deliveryServices.length; index++) {
            const service = this.deliveryServices[index];
            if (service.id === ev.target.value) {
                serviceData = service;
            }
        }
        this.setState({ service: ev.target.value });
        this.props.deliveryService(serviceData);
    };

    handleOrderPlaceChange(ev) {
        let idPlace = "";
        let coordPlace = "";
        for (let index = 0; index < this.deliveryPlaces.length; index++) {
            if (this.deliveryPlaces[index].name === ev.target.innerText) {
                idPlace = this.deliveryPlaces[index].id;
                coordPlace = this.deliveryPlaces[index].coordinates;
            }
        }
        this.setState({ place: idPlace, coordPlace:coordPlace });
        this.props.placeSelect(idPlace);
    };

    setInputLocationDetailValue(ev) {
        this.setState({ locationDetail: ev.target.value });
    };
    setInputUserPhoneValue(ev) {
        this.setState({ userPhone: ev.target.value });
    };
    setInputReferencyValue(ev) {
        this.setState({ paymentReferency: ev.target.value });
    };
    setInputDescriptionValue(ev) {
        this.setState({ paymentDescription: ev.target.value });
    };

    createOrderButtonAction(e) {
        e.preventDefault();
        this.props.createOrderButtonAction(this.state);
    };

    render() {

        const { paymentOptions, isProducts, modalityOptions, deliveryServices, deliveryPlaces } = this.props;
        this.paymentOptions = paymentOptions;
        this.isProducts = isProducts;
        this.modalityOptions = modalityOptions;
        this.deliveryServices = deliveryServices;
        this.deliveryPlaces = deliveryPlaces;

        return (<div className="form-order-container">
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{this.locates.modality_order_select_title}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.orderModality}
                    label={this.locates.modality_order_select_title}
                    onChange={this.handleOrderModalityChange}
                >
                    {this.modalityOptions.map((data, i) => {
                        return (<MenuItem key={i} value={data.id}>{data.name}</MenuItem>)
                    })}
                </Select>
            </FormControl>
            {this.state.branchSale === false ? <>
                <FormControl fullWidth sx={{marginTop:3}}>
                    <InputLabel id="demo-simple-select-label">{this.locates.delivery_order_service_select_title}</InputLabel>
                    <Select
                        defaultValue=""
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.service}
                        label={this.locates.delivery_order_service_select_title}
                        onChange={this.handleOrderServiceChange}
                    >
                        {this.deliveryServices.map((data, i) => {
                            return (<MenuItem key={i} value={data.id}>{data.name}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{marginTop:3}}>
                    <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        onChange={this.handleOrderPlaceChange}
                        autoComplete={true}
                        disableClearable={true}
                        options={this.deliveryPlaces.map((option) => option.name)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={this.locates.delivery_order_place_select_title}
                                slotProps={{
                                    input: {
                                        ...params.InputProps,
                                        type: 'search',
                                    },
                                }}
                            />
                        )}
                    />
                </FormControl>
                <FormControl fullWidth sx={{marginTop:3}}>
                    <TextField
                        label={this.locates.delivery_order_place_location_detail_title}
                        variant="outlined"
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        fullWidth
                        value={this.state.locationDetail}
                        onChange={this.setInputLocationDetailValue} />
                </FormControl>
                <FormControl fullWidth sx={{marginTop:3}}>
                    <TextField
                        id="outlined-size-normal"
                        label={this.locates.delivery_order_user_phone_title}
                        variant="outlined"
                        fullWidth
                        value={this.state.userPhone}
                        type="tel"
                        onChange={this.setInputUserPhoneValue} />
                </FormControl>
            </> : <></>}
            <FormControl fullWidth sx={{marginTop:3}}>
                <InputLabel id="demo-simple-select-label">{this.locates.payment_method_select_title}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.paymentMethod}
                    label={this.locates.payment_method_select_title}
                    onChange={this.handlePaymentMethodChange}
                >
                    {this.paymentOptions.map((data, i) => {
                        return (<MenuItem key={i} value={data.id}>{data.name}</MenuItem>)
                    })}
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{marginTop:3}}>
                <TextField
                    id="outlined-size-normal"
                    label={this.locates.create_order_input_ref_title}
                    variant="outlined"
                    fullWidth
                    value={this.state.paymentReferency}
                    onChange={this.setInputReferencyValue} />
                <TextField
                    id="outlined-size-normal"
                    label={this.locates.create_order_input_description_title}
                    variant="outlined"
                    fullWidth
                    value={this.state.paymentDescription}
                    onChange={this.setInputDescriptionValue}
                    sx={{marginTop:3}} />
                <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{ backgroundColor: "#67C3EF", marginTop:3, marginBottom:3 }}
                    onClick={this.createOrderButtonAction}>{this.locates.create_order_button_title}</Button>
            </FormControl>
        </div>);
    }
}