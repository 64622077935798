import React from "react";
import "./style.css"
import strings from "../../assets/strings/strings.json";
import logo_round from '../../assets/images/logo_round.png';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export class Header extends React.Component {
    constructor() {
        super();
        this.title = "";
        this.type = "";
        this.locates = strings;
        this.imgLogoIcon = logo_round;
        this.backButtonAction = this.backButtonAction.bind(this);
        this.closeSessionButtonAction = this.closeSessionButtonAction.bind(this);
    }

    backButtonAction(e) {
        e.preventDefault();
        this.props.backButton();
    };

    closeSessionButtonAction(e) {
        e.preventDefault();
        this.props.closeSession();
    };

    componentDidMount() {
    }


    render() {
        const { title, type } = this.props;
        this.title = title;
        this.type = type;
        return (<div className="header-container">
            <div className="navbar">
                {this.type === "main" ? <>
                    <a href="/dashboard">
                        <img src={this.imgLogoIcon} alt="Logo" className="header-icon-logo" />
                    </a>
                    <span onClick={this.closeSessionButtonAction} className="right">{this.locates.close_session}</span>
                </> : <>
                    <ArrowBackIosIcon className="back-button" onClick={this.backButtonAction} />
                    <h2 className="left">{this.title}</h2>
                </>}
            </div>
        </div>);
    }
}
