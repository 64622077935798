import { BasePage } from "../base-page";
import "./style.css";
/**
 * MUI
 */
import {
    TextField,
    Button,
    Typography,
    Backdrop,
    CircularProgress
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
//API
import { Login } from "../../model/api";
import { Navigate } from "react-router-dom";


const ID_PAGE = "login";

export class LoginPage extends BasePage {

    constructor() {
        super(ID_PAGE);
        this.state = {
            inputUserNameValue: "",
            inputUserPasswordValue: "",
            toggleBackdrop: false,
            toggleDialog: false,
            dialogMessage: "",
            navigate: null
        };
        this.setInputNameValue = this.setInputNameValue.bind(this);
        this.setInputPasswordValue = this.setInputPasswordValue.bind(this);
        this.loginButtonAction = this.loginButtonAction.bind(this);
        this.setToggleBackdrop = this.setToggleBackdrop.bind(this);
        this.setToggleDialog = this.setToggleDialog.bind(this);

        this.Transition = this.React.forwardRef(function Transition(props, ref) {
            return <Slide direction="up" ref={ref} {...props} />;
          });
    };


    setInputNameValue(ev) {
        this.setState({ inputUserNameValue: ev.target.value });
    };
    setInputPasswordValue(ev) {
        this.setState({ inputUserPasswordValue: ev.target.value });
    };

    loginButtonAction = async (e) => {
        e.preventDefault();
        this.setToggleBackdrop();
        const loginClass = new Login(this.state.inputUserNameValue, this.state.inputUserPasswordValue);
        const loginResponse = await loginClass.loginAPI();
        this.setToggleBackdrop();
        if(loginResponse !== null) {
            if (loginResponse.error === false) {
                this.setLocalStorageSession(loginResponse.data.session);
                this.setLocalStorageUserBranch(loginResponse.data.branch);
                this.setState({ navigate: "dashboard" });
            } else {
                this.setToggleDialog(loginResponse.message);
            }
        } else {
            this.setToggleDialog(this.locates.general_error);
        }
    };

    setToggleBackdrop(s) {
        this.setState({ toggleBackdrop: !this.state.toggleBackdrop });
    };

    setToggleDialog(message) {
        this.setState({ toggleDialog: !this.state.toggleDialog, dialogMessage: message });
    };


    render() {
        let { navigate } = this.state;
        return (<div className="base-page-container">
            {navigate && (
                <Navigate to={`/${navigate}`} replace={true} />
            )}
            <div className="login-page-container">
                <div className="login-page-image-logo-container">
                    <img src={this.imgLogoIcon} alt="Logo" className="login-page-image-logo" />
                </div>
                <div className="login-page-title-container">
                    <Typography
                        variant="h4"
                        gutterBottom>{this.locates.login_text_title}</Typography>
                </div>
                <div className="login-page-inputs-container">
                    <div className="login-page-input-container">
                        <TextField
                            id="outlined-size-normal"
                            label="Usuario"
                            variant="outlined"
                            fullWidth
                            value={this.state.inputUserNameValue}
                            onChange={this.setInputNameValue} />
                    </div>
                    <div className="login-page-input-container">
                        <TextField
                            id="outlined-password-input"
                            label="Contraseña"
                            type="password"
                            fullWidth
                            value={this.state.inputUserPasswordValue}
                            onChange={this.setInputPasswordValue} />
                    </div>
                    <div className="login-page-button-container">
                        <Button
                            disabled={!(this.state.inputUserNameValue.length > 0 && this.state.inputUserPasswordValue.length > 0)}
                            variant="contained"
                            size="large"
                            fullWidth
                            sx={this.buttonStyleBrandySecondary}
                            onClick={this.loginButtonAction}>{this.locates.login_button}</Button>
                    </div>
                </div>
            </div>
            <Dialog
                open={this.state.toggleDialog}
                TransitionComponent={this.Transition}
                keepMounted
                onClose={_ => this.setToggleDialog("")}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{"Alerta"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {this.state.dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={_ => this.setToggleDialog("")}>Disagree</Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.state.toggleBackdrop}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>);
    }
}